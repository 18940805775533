import React, { useEffect, useState, Suspense } from 'react';
import GLBModel from '../components/3danimLogo/glbModel';
import GLBModelmobile from '../components/3danimLogo/glbModelmobile';
import Glossy from '../components/glossycomp/Glossy';
import ParticleBackground from '../components/3dBackg/3dBackg';
import EnhancedParticleBackground from '../components/3dBackg/3DBackgroud';
import MilkyWayBackground from '../components/3dBackg/milkyWay';
import MilkyWayFog from '../components/3dBackg/milkyWayFog';
import { Canvas } from '@react-three/fiber';
import { Billboard } from '@react-three/drei';

import './home.css';

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth >= 360 && window.innerWidth <= 932);

  useEffect(() => {
    // Add a unique class to the body when this component mounts
    document.body.classList.add('homepage-background');
    document.body.style.cursor = 'grab';

    const handleResize = () => {
      setIsMobile(window.innerWidth >= 390 && window.innerWidth <= 932);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      document.body.classList.remove('homepage-background');
      document.body.style.cursor = 'default'; // Reset cursor when leaving the homepage
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="hpcontain">
        <Glossy />
        <div className="homepagewrap">
          <div className="homepageinner">
            <div className="canvas-container">
              <Canvas
                camera={{ position: [0, 0, 10.5], zoom:0.95}}
                shadows
                fog={{ color: 'black', near: 1, far: 3 }}
              >
                <ambientLight castShadow />
                <spotLight castShadow position={[0, 1, 0]} color="white" intensity={1.2} />
                <spotLight position={[0, 0, 1]} color="white" intensity={1.2} />
                <Suspense fallback={null}>
                  {isMobile ? <GLBModelmobile /> : <GLBModel />}
                </Suspense>
                <ParticleBackground />
                <Billboard>
                  <MilkyWayFog />
                </Billboard>
                <MilkyWayBackground />
                <ambientLight intensity={0.3} />
              </Canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
